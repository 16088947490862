<template><div>
	<h3>View Rubrics</h3>

<p>CASE® Rubrics can be associated to any item type (grade, strand, cluster, standard, etc.) within a framework. Rubrics can be viewed from both the <span v-html="link('treeview', 'tree view')"></span>  and framework items with Has Rubric… associations can be viewed and exported from <span v-html="link('tableitemsview', 'table view')"></span> within Satchel once associations have been enabled.</p>

<p><strong>Find Rubrics from Tree View:</strong>
    <ol>
        <li>Open a framework (subject area) to locate a rubric.</li>
        <img srcset="/docimages/associations_option.png 3x" class="k-help-img block">
        <li>Select the ASSOCIATIONS OPTIONS icon from the toolbar to check the box to <strong>Show associations… </strong>for the selected framework and check the box(es) to <strong>Show association types…</strong> for <strong>Has Rubric</strong> or <strong>Has Rubric Criterion.</strong></li>
        <img srcset="/docimages/has_rubric.png 2.5x" class="k-help-img block">
        <li>From the <strong>tree view</strong> of the framework, scroll down to any item (row within the framework) to find an <img srcset="/docimages/association_icon.png 2x"> associations icon and continue to drill down through the child items until you find a <img srcset="/docimages/association_icon_1.png 2x"> rubric association.</li>
        <li>Click any item with a <img srcset="/docimages/association_icon_1.png 2x"> rubric association to reveal the <span v-html="link('item_card', 'item card')"></span> on the right with the rubric tile.</li>
        <img srcset="/docimages/associated_rubric_icon.png 3x" class="k-help-img block">
        <li>Click the <strong>rubric tile</strong> to open the rubric window. From here print or export the rubric (coming soon).</li>
        <img srcset="/docimages/rubric_window.png 1x" class="k-help-img block">
    </ol>
</p>

<br>
<br>

<p><strong>Find Framework Items with Has Rubric… associations from Table View:</strong>
    <ol>
        <li>Open a framework (subject area) to locate a rubric.</li>
        <img srcset="/docimages/associations_option.png 3x" class="k-help-img block">
        <li>Select the ASSOCIATIONS OPTIONS icon from the framework toolbar to check the box to <strong>Show associations…</strong> for the selected framework and check the box(es) to <strong>Show association types…</strong> for Has Rubric or <strong>Has Rubric Criterion.</strong></li>
        <img srcset="/docimages/has_rubric.png 2.5x" class="k-help-img block">
        <li>Select the SWITCH TO TABLE VIEW icon from the framework toolbar.</li>
        <img srcset="/docimages/table_view_option.png 2.5x" class="k-help-img block">
        <li>Verify that you are viewing the ASSOCIATIONS for the table view of your selected framework, then choose the <img srcset="/docimages/brown_show_filters.png 4.5x"> SHOW FILTERS button to verify the columns of data you want to view from the framework.</li>
        <img srcset="/docimages/show_filters_associations.png 3.7x" class="k-help-img block">
        <li>Check off the data to filter the table view. Scroll beneath the filters to view your filtered selections.</li>
        <img srcset="/docimages/association_type.png 5x" class="k-help-img block">
        <li>Sort the column headers, use the search to find specific information within the table, or export the filtered data into a CSV file.</li>
    </ol>
</p>


</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>